import React from 'react';
import {Paper} from '@material-ui/core';
import './EditHoliday.scss';
import {HolidayEditForm} from "../../components";

export const EditHoliday: React.FC = () => {
    return (
        <React.Fragment>
            <Paper elevation={3} className="edit-holiday">
                <HolidayEditForm/>
            </Paper>
        </React.Fragment>
    );
};