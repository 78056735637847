import {combineReducers, configureStore} from '@reduxjs/toolkit';
import {Configuration} from '../Configuration';
import {apiHoliday} from "./api";
import holidayReducer from '../store/holiday';

const rootReducer = combineReducers({
    [apiHoliday.reducerPath]: apiHoliday.reducer,
    ["holidays"]: holidayReducer

});
export const store = configureStore({
    devTools: !Configuration.isProduction,
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([apiHoliday.middleware]),

});

export type AppState = ReturnType<typeof store.getState>;
