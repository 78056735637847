import React from 'react';
import {Route, RouteProps, Switch} from 'react-router-dom';
import {EditHoliday, EditHolidayDetail, Home, NewDateToHoliday, NewHoliday} from './views';

export enum AppRoutes {
    HOME = '/',
    NEW_HOLIDAY = '/holiday/new',
    NEW_DATE_TO_HOLIDAY = '/holiday/new/date',
    EDIT_HOLIDAY_DETAIL = '/holiday/detail/:id/edit',
    EDIT_HOLIDAY = '/holiday/:id/edit',
}

const routes: RouteProps[] = [
    {path: AppRoutes.HOME, exact: true, component: Home},
    {path: AppRoutes.NEW_HOLIDAY, exact: true, component: NewHoliday},
    {path: AppRoutes.NEW_DATE_TO_HOLIDAY, exact: true, component: NewDateToHoliday},
    {path: AppRoutes.EDIT_HOLIDAY_DETAIL, exact: true, component: EditHolidayDetail},
    {path: AppRoutes.EDIT_HOLIDAY, exact: true, component: EditHoliday},
];

export const Routes: React.FC = () => {
    return (
        <React.Fragment>
            <Switch>
                {routes.map((route, i) => (
                    <Route key={i} {...route} />
                ))}
            </Switch>
        </React.Fragment>
    );
};
