import {Holiday} from "../types";
import {apiHoliday} from "./api";
import {
    createSlice,
    PayloadAction,
    createEntityAdapter,
    EntityState
} from "@reduxjs/toolkit";
import {AppState} from "./store";


const holidayAdapter = createEntityAdapter<Holiday>({
    //selectId: (holiday) => holiday.id,
    sortComparer: (a, b) => a.attributes.name.localeCompare(b.attributes.name)
});

const initialState: EntityState<Holiday> = holidayAdapter.getInitialState();

export const holidayApi = apiHoliday.injectEndpoints({
    endpoints: (builder) => ({
        getAllHoliday: builder.query({
            query: () => "holidays",
            providesTags: [{type: "Holidays", id: "holidayList"}]
        }),
        getAllHolidayWithDetails: builder.query({
            query: (_arg) => {
                const {size, number} = _arg;
                const pageSize = size || 100;
                const pageNumber = number || 1;
                return `holidays/details/all?page%5Bnumber%5D=${pageNumber}&page%5Bsize%5D=${pageSize}`
            },
            providesTags: [{type: "Holidays", id: 'holidayList'}]
        }),
        getHolidayWithDetails: builder.query({
            query: (id) => `holidays/${id}/details`,
            providesTags: [{type: "Holidays", id: "holiday"}]
        }),
        addHolidayWithDetails: builder.mutation<Holiday, { data: Partial<Holiday> }>({
            query: (body) => ({
                url: `holidays`,
                method: "POST",
                body: JSON.stringify(body)

            }),
            invalidatesTags: [{type: 'Holidays', id: 'holidayList'}]
        }),
        editHolidayWithDetails: builder.mutation<Holiday, { id: number; data: Holiday }>({
            query: ({id, data}) => ({
                url: `holidays/${id}`,
                method: "PUT",
                body: JSON.stringify({data: data}),
            }),
            invalidatesTags: [{type: "Holidays", id: "holidayList"}, {type: "Holidays", id: "holiday"}]
        })
    }),
    overrideExisting: false,
});

export const holidaySlice = createSlice({
    name: "holidays",
    initialState,
    reducers: {
        // Use the PayloadAction type to declare the contents of `action.payload`
        merge: (state, action: PayloadAction<Holiday[]>) => {
            holidayAdapter.upsertMany(state, action.payload);
        }
    },
    extraReducers: (builder) => {
        // we'll match on the async action or the manual increment being that both have a payload of type `number`
        builder.addMatcher(holidayApi.endpoints.getAllHolidayWithDetails.matchFulfilled, (state, {payload}) => {
            holidayAdapter.upsertMany(state, payload.data);
        })
    }
});


export const {
    useGetAllHolidayQuery,
    useGetAllHolidayWithDetailsQuery,
    useGetHolidayWithDetailsQuery,
    useAddHolidayWithDetailsMutation,
    useEditHolidayWithDetailsMutation,
    usePrefetch
} = holidayApi;

export const holidaysSelector = (state: AppState) => state.holidays;

export default holidaySlice.reducer;
