import React, {useEffect, useState} from "react";
import {AppRoutes} from "../../Routes";
import {
    HolidayAttributes,
    HolidayDetailInformationType,
    HolidayDetailsValue,
    Message
} from "../../types";
import {HolidayScopeTypes} from "@common/types";
import {UiUtils} from "../../utils";
import {
    AlertMessage,
    Input,
    Select
} from '@vacasa/react-components-lib';
import {useHistory, useParams} from "react-router-dom";
import "./HolidayEditForm.scss";
import {
    useEditHolidayWithDetailsMutation,
    useGetAllLocationQuery,
    useGetHolidayWithDetailsQuery
} from "../../store";
import {Loading, HolidayDetailInformation, FormHeader} from "..";
import {isEmpty, merge} from "lodash";

interface ILocationParams {
    id: string;
}

export const HolidayEditForm = () => {
    const {id: holiday_id} = useParams<ILocationParams>();
    const [editHolidayWithDetails] = useEditHolidayWithDetailsMutation();
    const history = useHistory();
    const {data: holiday, isFetching: isFetchingHoliday} = useGetHolidayWithDetailsQuery(holiday_id);
    const {data: locations, isFetching} = useGetAllLocationQuery();
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);
    const [actualHoliday, setActualHoliday] = useState<HolidayAttributes>();
    const [newHoliday, setNewHoliday] = useState<Partial<HolidayAttributes>>();
    const [holidayDetails, setHolidayDetails] = useState<HolidayDetailsValue[]>();
    const [holidayDetailData, setHolidayDetailData] = useState<{ [key: string]: HolidayDetailInformationType }>({});
    const [invalidHolidayDetailData, setInvalidHolidayDetailData] = useState<{ [key: string]: HolidayDetailInformationType }>({});


    useEffect(() => {
        if (!isEmpty(holiday)) {
            const {id, legacy_id, name, scope, description, holiday_details} = holiday.data.attributes;
            setNewHoliday(() => ({
                name,
                scope,
                description
            }));
            setActualHoliday(() => ({
                id,
                legacy_id,
                name,
                scope,
                description,
                holiday_details
            }));
            setHolidayDetails(() => UiUtils.getHolidayDetailValues(holiday_details, scope));
        }
    }, [holiday])

    useEffect(() => {
        if (!isEmpty(holiday) && !isEmpty(newHoliday)) {
            const {name, description, scope} = holiday.data.attributes;
            if (
                !isEmpty(holidayDetailData) ||
                newHoliday.name !== name ||
                newHoliday.scope !== scope ||
                newHoliday.description !== description
            ) {
                setUiAlert(UiUtils.getPendingChangesMessage());
                setIsSaveDisabled(false);
            } else {
                setUiAlert(null);
                setIsSaveDisabled(true)
            }
        }
    }, [newHoliday, holidayDetailData, holiday])

    const hasInvalidHolidayDetails = (): boolean => {
        const invalidActualDetails = UiUtils.getInvalidHolidayDetailsValue(holidayDetails, newHoliday.scope as HolidayScopeTypes, holidayDetailData);
        const invalidDetails = UiUtils.getInvalidHolidayDetailInformationType(holidayDetailData, newHoliday.scope as HolidayScopeTypes);
        if (invalidDetails || invalidActualDetails) {
            setUiAlert({type: "error", content: "Please check the holiday detail(s)"});
            setInvalidHolidayDetailData(() => merge(invalidDetails, invalidActualDetails))
            return true;
        }
        return false;
    }

    const handleSave = async () => {
        if (hasInvalidHolidayDetails()) return;
        const payload = UiUtils.getEditHolidayPayload(actualHoliday, newHoliday, holidayDetailData);
        try {
            await editHolidayWithDetails({id: actualHoliday.id, data: payload}).unwrap();
            setUiAlert(UiUtils.getSaveSuccessMessage());
            setTimeout(() => {
                history.push(AppRoutes.HOME)
            }, 3000)
        } catch (error) {
            setUiAlert(UiUtils.getErrorMessage(error.data, error.status));
        }
    }

    const handleBackOrCancel = () => {
        history.push(AppRoutes.HOME);
    }

    const handleInvalidDetails = (data, key) => {
        if (!isEmpty(invalidHolidayDetailData) && Object.keys(invalidHolidayDetailData).includes(key)) {
            if (UiUtils.isValidHolidayDetail(data, newHoliday.scope)) {
                delete invalidHolidayDetailData[key];

                if (isEmpty(invalidHolidayDetailData)) {
                    setUiAlert(() => null);
                }
            }
        }
    }

    const handleChangeDetail = (data, key) => {
        if (data === null) {
            setHolidayDetailData((prev) => {
                const newHolidayDetailData = {...prev};
                delete newHolidayDetailData[key];
                return newHolidayDetailData;
            });
        } else {
            setHolidayDetailData((prev) => ({...prev, [key]: data}));
        }
        handleInvalidDetails(data, key);
    }

    const handleChange = (value: string, key: keyof HolidayAttributes) => {
        setNewHoliday((prev) => ({...prev, [key]: value.length > 255 ? value.substring(0, 255) : value}));
    }
    
    return (
        <React.Fragment>
            <FormHeader title="Edit Holiday" backButtonTooltip="Holiday List" handleBack={handleBackOrCancel}
                        handleCancel={handleBackOrCancel} handleSave={handleSave} disabledSave={isSaveDisabled}/>
            {uiAlert ? <div className={'alert-container'}>
                <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type} height="small"/>
            </div> : null}
            {isFetching || isFetchingHoliday || isEmpty(actualHoliday) || isEmpty(newHoliday) ?
                <Loading className="loading-information"/> : (
                    <div className="holiday-edit-form-container">
                        <div>
                            <div className='top-content'>
                                <div className='input-container'>
                                    <p>Holiday Name:</p>
                                    <Input
                                        customClass="input"
                                        type="text"
                                        value={newHoliday.name}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, "name")}

                                    />
                                </div>
                                <div className={'select-container'}>
                                    <p>Scope:</p>
                                    <Select
                                        value={newHoliday.scope}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value as HolidayScopeTypes, "scope")}
                                        options={UiUtils.getScopeFilterOptions().slice(1, 5)}
                                        customClass={"select"}
                                    />
                                </div>
                                <div className='input-container'>
                                    <p>Description:</p>
                                    <Input
                                        customClass="input"
                                        type="text"
                                        value={newHoliday.description}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => handleChange(e.target.value, "description")}
                                    />
                                </div>
                            </div>
                            {holidayDetails.map((holiday_detail) => {
                                    const isInvalid = !isEmpty(invalidHolidayDetailData) ? Object.keys(invalidHolidayDetailData).includes(holiday_detail.key) : false;
                                    return (
                                        <HolidayDetailInformation
                                            key={holiday_detail.key}
                                            holidayScope={newHoliday.scope as HolidayScopeTypes}
                                            holiday_detail={holiday_detail}
                                            locations={locations}
                                            onChangeDetail={handleChangeDetail}
                                            invalidDetail={isInvalid}
                                        />
                                    )
                                }
                            )}
                        </div>
                    </div>
                )}
        </React.Fragment>
    );
}