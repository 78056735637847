import {DataSourceBuilder} from "../VirtualizedTable/DataSourceBuilder";
import {VirtualizedTable} from "../VirtualizedTable/VirtualizedTable";
import {Icon, Tooltip, Modal} from '@vacasa/react-components-lib';
import React, {useEffect, useState} from "react";
import {ChangeLogValues} from "../../types";
import './ChangeLog.scss';
import {useGetAllChangesHistoryQuery} from "../../store";
import {Loading} from "../Loading/Loading";
import _ from "lodash";
import {UiUtils} from "../../utils";
import SyntaxHighlighter from 'react-syntax-highlighter';

export const ChangeLog: React.FC = () => {
    const {data, isFetching} = useGetAllChangesHistoryQuery();
    const [changeLogData, setChangeLogData] = useState<ChangeLogValues[]>([]);
    const [isShowingModal, setIsShowingModal] = useState<boolean>(false);
    const [changeHistoryData, setChangeHistoryData] = useState({});

    useEffect(() => {
        if(!_.isEmpty(data) && !isFetching) {
            const transformedData: ChangeLogValues[] = UiUtils.getChangeLogValues(data);
            setChangeLogData(transformedData);
        }
    }, [data, isFetching]);

    const JsonDetail = (change) => (
        <div className="json-detail">
            <SyntaxHighlighter language="json">{JSON.stringify(change.changeHistory, null, 2)}</SyntaxHighlighter>
        </div>
    );

    const handleChangeLogDetails = (changeDetails) => {
        setIsShowingModal(true);
        setChangeHistoryData(changeDetails);
    }

    const builder = new DataSourceBuilder<ChangeLogValues>();
    builder.addColumn({field: 'change_date', label: 'Change Date', displayConfiguration: {justifyContent: 'left'}});
    builder.addColumn({field: 'holiday', label: 'Holiday'});
    builder.addColumn({field: 'analyst_name', label: 'Analyst Name'});
    builder.addColumn({field: 'change_type', label: 'Change Type'});
    builder.addColumn({field: 'change_details', label: 'Details {...}', fieldConfiguration: {
        format: (changeDetails) => {
            return (
                <Tooltip message="Open JSON details" placement="right">
                    <div className="change-log-details">
                        <Icon.PlusCircle height={24} width={24} onClick={() => handleChangeLogDetails(changeDetails)}/>
                    </div>
                </Tooltip>
            );
        }
    }});

    builder.addPagination({
        remote: false
    });

    return (
        <>
            <Modal
                showModal={isShowingModal}
                setShowModal={setIsShowingModal}
                size='medium'
                canExit={true}
            >
                <JsonDetail changeHistory={changeHistoryData}/>
            </Modal>
            <div className="change-log-list">
                {isFetching ?
                    <Loading className="change-log-loading"/>
                    : <VirtualizedTable className="change-log-table" dataSource={builder.build(changeLogData)} onRowChange={() => null} />
                }

            </div>
        </>
    )
}