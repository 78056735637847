import React, {useEffect, useState} from 'react';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import './TableAccordion.scss';
import {TableRowProps} from "react-virtualized";
import {Badge, Icon, Tooltip} from '@vacasa/react-components-lib';
import {UiUtils} from "../../utils";
import _ from "lodash";
import {Link} from 'react-router-dom';
import {AppRoutes} from "../../Routes";

interface TableAccordionProps {
    tableProps: TableRowProps;
    expanded: string | boolean;
    handleChange: (id: string, index: number, holidayDetailsLength: number) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => void;
    handleDelete: (id: number, name: string) => void;
}

export const TableAccordion: React.FC<TableAccordionProps> = (props) => {

    const {tableProps, expanded, handleChange, handleDelete} = props;
    const [holidayDetails, setHolidayDetails] = useState([]);

    const styles = {
        ...tableProps.style
    };

    useEffect(() => {
        const holidayDetailsFromData: any[] = tableProps.rowData.holiday_details;

        setHolidayDetails(holidayDetailsFromData);
    }, [tableProps.rowData.holiday_details]);

    return (
        <div key={tableProps.rowData.key} className="accordion-row" style={styles}>
            <MuiAccordion key={tableProps.rowData.key} expanded={expanded === tableProps.rowData.key}
                          onChange={handleChange(tableProps.rowData.key, tableProps.index, holidayDetails.length)}>
                <MuiAccordionSummary
                    className={"accordion-summary"}>
                    {tableProps.columns}
                </MuiAccordionSummary>
                <MuiAccordionDetails className={"accordion-details"}>
                    <table className={"table"}>
                        <thead>
                        <tr className={"table-header"}>
                            <th>Location</th>
                            <th>Weight</th>
                            <th>Weekdays</th>
                            <th>Status</th>
                            <th>First Night</th>
                            <th>Last Night</th>
                            <th>Action</th>
                        </tr>
                        </thead>
                        <tbody>
                        {!_.isEmpty(holidayDetails) ? holidayDetails.map((hd) => {
                                return (
                                    <tr className={"table-body"}>
                                        <td>{hd.location}</td>
                                        <td>{hd.weight}</td>
                                        <td>{hd.split_weekdays}</td>
                                        <td>
                                            <Badge
                                                disabled={false}
                                                closeButton={false}
                                                isPill={true}
                                                text={UiUtils.getTextForBadge(hd.status)}
                                                outline={false}
                                                variant={UiUtils.colorStatus(hd.status)}
                                            />
                                        </td>
                                        <td>{hd.first_night}</td>
                                        <td>{hd.last_night}</td>
                                        <td>
                                            <div className={'iconsDiv'}>
                                                <Link
                                                    to={{pathname: AppRoutes.EDIT_HOLIDAY_DETAIL.replace(':id', hd.key),
                                                        state: {
                                                            holiday_id: tableProps.rowData.key,
                                                            name: tableProps.rowData.holiday_name,
                                                            detail: hd
                                                        }
                                                    }}
                                                >
                                                    <Icon.Edit3 className={"editIcon"} height={20} width={20}/>
                                                </Link>

                                                <Tooltip
                                                    message={holidayDetails.length > 1 ? "" : "Holidays must have at least one detail"}>
                                                    <span>
                                                        <Icon.Trash2
                                                            className={holidayDetails.length > 1 ? 'pointer' : 'disabled-icon'}
                                                            height={20}
                                                            width={20}
                                                            onClick={() => handleDelete(hd.key, hd.location ?? '')}
                                                        />
                                                    </span>
                                                </Tooltip>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr className={"table-body"}>
                                <td colSpan={7} className={"empty-record"}> No records</td>
                            </tr>
                        }
                        </tbody>
                    </table>
                </MuiAccordionDetails>
            </MuiAccordion>
        </div>
    );
}
