import {createApi, fetchBaseQuery} from "@reduxjs/toolkit/query/react";
import {getHeaders} from "./utils";

export const apiHoliday = createApi({
    reducerPath: "holidayApi",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_HOLIDAY_MANAGER_API}/api/v1`,
        prepareHeaders: (headers) => {
            return getHeaders(headers);
        }
    }),
    tagTypes: ["HolidayDetails", "Holidays", "ChangesHistory", "Locations"],
    endpoints: () => ({})
})

