import {Location} from "../types";
import {apiHoliday} from "./api";

export const locationApi = apiHoliday.injectEndpoints({
    endpoints: (builder) => ({
        getAllLocation: builder.query<{data: Location[] }, void>({
            query: () => "location",
            providesTags: [{type: "Locations", id: "locationList"}]
        }),
    }),
    overrideExisting: false,
})

export const {useGetAllLocationQuery} = locationApi;

