import React, {useCallback, useEffect, useState} from 'react';
import {FilterConfiguration, Loading, VirtualizedTable} from '../index';
import {Icon, Tooltip, AlertMessage, TextBox} from '@vacasa/react-components-lib';
import './HolidayList.scss';
import {DataSourceBuilder} from "../VirtualizedTable/DataSourceBuilder";
import {HolidayListValues, Location, Message, ScopeOptions, StatusOptions} from "../../types";
import {holidaysSelector, useGetAllHolidayWithDetailsQuery, usePrefetch} from "../../store";
import {UiUtils} from "../../utils";
import {AppRoutes} from "../../Routes";
import {Link} from 'react-router-dom';
import {useSelector} from 'react-redux';
import {isEmpty, map} from "lodash";


export const HolidayList: React.FC = () => {

    const {data, isFetching} = useGetAllHolidayWithDetailsQuery({});
    const prefetchPage = usePrefetch('getAllHolidayWithDetails');

    const holidaysInfo = useSelector(holidaysSelector);

    const [holidayData, setHolidayData] = useState<HolidayListValues[]>([]);
    const [filteredData, setFilteredData] = useState<HolidayListValues[]>(holidayData);
    const [holidayNameFilter, setHolidayNameFilter] = useState<string>("");
    const [holidayStatusFilter, setHolidayStatusFilter] = useState<StatusOptions>("All");
    const [holidayScopeFilter, setHolidayScopeFilter] = useState<ScopeOptions>("All");
    const [holidayYearFilter, setHolidayYearFilter] = useState<string>("All");
    const [holidayRegionFilter, setHolidayRegionFilter] = useState<Location[]>([]);
    const [includeRecordEmpty] = useState<boolean>(false);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);


    const [hasPrefetchedAll, setHasPrefetchedAll] = useState(false)

    useEffect(() => {
        if (!hasPrefetchedAll) {
            if (data?.data && data?.meta?.pagination?.pages > 1) {
                [...new Array(data?.meta?.pagination?.pages)].forEach((page, index) => {
                    if (index >= data?.meta?.pagination?.pages) return
                    prefetchPage({number: index + 1}, {force: true});
                })
                setHasPrefetchedAll(true)
            }
        }
    }, [data, prefetchPage, hasPrefetchedAll]);

    useEffect(() => {
        if (!isEmpty(holidaysInfo)) {
            const transformedData: HolidayListValues[] = UiUtils.getHolidayListWithDetailValues(holidaysInfo?.entities ?? []);
            setHolidayData(transformedData);
        }
    }, [holidaysInfo, isFetching, prefetchPage])

    useEffect(() => {
        if (uiAlert) {
            setTimeout(() => {
                setUiAlert(null);
            }, 10000)
        }
    }, [uiAlert])


    const filterData = useCallback(() => {
        if (holidayStatusFilter !== "All" || holidayRegionFilter !== [] || holidayScopeFilter !== "All" || holidayYearFilter !== "All" || !isEmpty(holidayNameFilter)) {
            let result = JSON.parse(JSON.stringify(holidayData)).filter((holiday) => holiday.holiday_name.toLowerCase().includes(holidayNameFilter.toLowerCase()) ? holiday : null) as HolidayListValues[];
            if (holidayScopeFilter !== "All") {
                result = result.filter(holiday => holiday.scope === holidayScopeFilter);
            }
            const regionIds = map(holidayRegionFilter, (region: Location) => region.attributes.id);
            result.forEach(holiday => {
                let auxHolidayDetails = [];
                holiday.holiday_details.filter((holidayDetails) => {
                    if (holidayStatusFilter !== "All" && UiUtils.getTextForBadge(holidayDetails.status) !== holidayStatusFilter) {
                        return null;
                    }
                    if (holidayYearFilter !== "All" && new Date(holidayDetails.first_night).getFullYear().toString() !== holidayYearFilter) {
                        return null;
                    }
                    if (!isEmpty(holidayRegionFilter)) {
                        if (holidayDetails.location_type === 'CITY' && !regionIds.includes(holidayDetails.parent_id)) {
                            return null;
                        }
                        if (holidayDetails.location_type === 'REGION' && !regionIds.includes(holidayDetails.location_id)) {
                            return null;
                        }
                        if (holidayDetails.location_type !== 'CITY' && holidayDetails.location_type !== 'REGION') {
                            return null;
                        }
                    }
                    auxHolidayDetails.push(holidayDetails);
                    return null;
                })
                holiday.holiday_details = auxHolidayDetails;
                holiday.record = holiday.holiday_details.length;
            });
            return includeRecordEmpty ? result : result.filter((holiday) => {
                return holiday.holiday_details.length > 0
            }) as HolidayListValues[];
        }
        return holidayData;
    }, [holidayNameFilter, holidayStatusFilter, holidayRegionFilter, holidayScopeFilter, holidayYearFilter, holidayData, includeRecordEmpty]);

    useEffect(() => {
        const filtered = filterData();
        setFilteredData(filtered);
    }, [holidayNameFilter, holidayStatusFilter, holidayScopeFilter, holidayYearFilter, filterData]);

    const builder = new DataSourceBuilder<HolidayListValues>();
    builder.addColumn({
        label: 'ID', field: 'id', displayConfiguration: {justifyContent: 'left'},
        fieldConfiguration: {
            customLeftComponent: () => {
                return <Icon.ChevronRight className='imagen-collapse' height={24} width={24}/>;
            }
        }
    });
    builder.addColumn({label: 'Holiday Name', field: 'holiday_name', displayConfiguration: {justifyContent: 'left'},});
    builder.addColumn({label: 'Scope', field: 'scope', displayConfiguration: {customCssStyle: {marginLeft: "50px"}}});
    builder.addColumn({
        label: 'Description',
        field: 'description',
        displayConfiguration: {justifyContent: 'left'},
        fieldConfiguration: {
            format: (description) => {
                return (
                    <Tooltip message={description ? description : ''} placement="right">
                        <div>
                            <TextBox title='' text={description ? description : ''} maxLength={30}/>
                        </div>
                    </Tooltip>
                );
            }
        }
    });
    builder.addColumn({label: 'Records', field: 'record'});
    builder.addColumn({
        label: '', field: 'action', displayConfiguration: {justifyContent: 'right'},
        fieldConfiguration: {
            customLeftComponent: (data) => {
                return (
                    <Link
                        to={{pathname: AppRoutes.EDIT_HOLIDAY.replace(':id', data.key)}}
                    >
                        <div className="edit-button-list">
                            <span>Edit</span>
                            <div className={"image-container"}>
                                <Icon.Edit3 height={24} width={24}/>
                            </div>
                        </div>
                        ;
                    </Link>
                )
            },
            customRightComponent: (data) => {
                return (
                    <Link
                        to={{
                            pathname: AppRoutes.NEW_DATE_TO_HOLIDAY,
                            state: {name: data.holiday_name, holiday_id: data.id, scope: data.scope}
                        }}
                    >
                        <div className="add-date-button-list">
                            <span>Add date</span>
                            <div className={"image-container"}>
                                <Icon.Calendar height={24} width={24}/>
                            </div>
                        </div>
                        ;
                    </Link>
                )
            }
        }
    });
    builder.addPagination({remote: false});

    return (
        <div className="holiday-list">
            <div className="holiday-list-search">
                <FilterConfiguration
                    nameFilter={holidayNameFilter}
                    onChangeForName={(filter) => setHolidayNameFilter(filter.target.value)}
                    statusFilter={holidayStatusFilter}
                    onChangeForStatus={(filter) => setHolidayStatusFilter(filter.target.value)}
                    regionFilter={holidayRegionFilter}
                    onChangeForRegion={(event, filter) => setHolidayRegionFilter(filter)}
                    yearFilter={holidayYearFilter}
                    onChangeForYear={(filter) => setHolidayYearFilter(filter.target.value)}
                    scopeFilter={holidayScopeFilter}
                    onChangeForScope={(filter) => setHolidayScopeFilter(filter.target.value)}
                />
            </div>
            <div className="add-button">
                {uiAlert && (
                    <AlertMessage customClass="alert-message" text={uiAlert?.content} type={uiAlert?.type}
                                  height="small"/>
                )}
                <Tooltip message="Add Holiday">
                    <Link to={AppRoutes.NEW_HOLIDAY}>
                         <span className="add-holiday-img">
                            <Icon.PlusCircle className="pointer" height={24} width={24}/>
                        </span>
                    </Link>

                </Tooltip>
            </div>
            <div>
                {isFetching
                    ? <Loading className="holiday-list-loading"/>
                    : <VirtualizedTable
                        className="holiday-list-table"
                        dataSource={builder.build(filteredData)}
                        onRowChange={() => null}
                        isAccordion={{
                            handleUiAlert: (message: Message) => setUiAlert(message)
                        }}
                    />
                }
            </div>
        </div>
    );
};
