import React, {useEffect, useState} from 'react';
import {
    AlertMessage,
    AutoCompleteSelect,
    ButtonGroup,
    Icon,
    Input,
    Select,
    Tooltip
} from '@vacasa/react-components-lib';
import './HolidayForm.scss';
import {DateRangePickers} from "../DateRangePickers/DateRangePickers";
import {UiUtils} from "../../utils";
import {Detail, Holiday, HolidayFormOptions, HolidayFormType, Location, Message, SelectOption} from "../../types";
import {Switch} from '@material-ui/core';
import {NumberInput} from "../NumberInput/NumberInput";
import {ButtonProps} from '@vacasa/react-components-lib/lib/components/Button/Button';
import {useGetAllLocationQuery} from "../../store/location";
import {HolidayScopeTypes, LocationTypes, Status} from "@common/types";
import {Loading} from "../Loading/Loading";
import {useHistory, useLocation} from 'react-router-dom'
import _ from "lodash";
import {addDays} from "date-fns";
import {useAddHolidayWithDetailsMutation} from "../../store/holiday";
import {AppRoutes} from "../../Routes";
import {useAddHolidayDetailMutation, useUpdateHolidayDetailMutation} from "../../store/holiday-details";


interface HolidayFormProps {
    type: HolidayFormType
}

export const HolidayForm: React.FC<HolidayFormProps> = (props) => {
    const {type} = props
    const history = useHistory();
    const [locationData, setLocationsData] = useState<Location[]>()
    const [addHolidayWithDetails] = useAddHolidayWithDetailsMutation();
    const [addHolidayDetail] = useAddHolidayDetailMutation();
    const [updateHolidayDetail] = useUpdateHolidayDetailMutation();
    const {data: locations, isFetching} = useGetAllLocationQuery();
    const data = useLocation()
    const currentData = type === HolidayFormOptions.EDIT ? data.state['detail'] : '';
    const today = new Date();
    const [startDate, setStartDate] = useState<Date>(!_.isNil(currentData.first_night) ? UiUtils.transformStringToDate(currentData.first_night) : today);
    const [endDate, setEndDate] = useState<Date>(!_.isNil(currentData.last_night) ? UiUtils.transformStringToDate(currentData.last_night) : addDays(today, 1));
    const [holidayName, setHolidayName] = useState<string>();
    const [holidayDescription, setHolidayDescription] = useState<string>();
    const [holidayNote, setHolidayNote] = useState<string>(!_.isNil(currentData.note) ? currentData.note : null);
    const [holidayScope, setHolidayScope] = useState<HolidayScopeTypes>();
    const [holidayCountry, setHolidayCountry] = useState<SelectOption>(!_.isNil(currentData.location) && currentData.location_type === LocationTypes.COUNTRY ? {
        value: currentData.location_id,
        display: currentData.location
    } : null);
    const [holidayRegion, setHolidayRegion] = useState<SelectOption>();
    const [holidayCity, setHolidayCity] = useState<SelectOption>();
    const [isActive, setIsActive] = useState<boolean>(!_.isNil(currentData.status) && currentData.status === Status.ENABLE);
    const [isWeekDay, setIsWeekDay] = useState<boolean>(!_.isNil(currentData.split_weekdays) && currentData.split_weekdays === 1);
    const [holidayWeight, setHolidayWeight] = useState<number>(!_.isNil(currentData.weight) ? currentData.weight : null);
    const [parentLocation, setParentLocation] = useState<{ value: number, display: string, type: string }[]>();
    const [isSaveDisabled, setIsSaveDisabled] = useState<boolean>(true);
    const [uiAlert, setUiAlert] = useState<Message | null>(null);

    const handleSave = async () => {
        if (type === HolidayFormOptions.ADD) {
            const newHoliday: { data: Partial<Holiday> } = {
                data: {
                    type: 'holiday',
                    attributes: {
                        name: holidayName,
                        scope: holidayScope,
                        description: holidayDescription,
                        holiday_details: [{
                            note: !_.isNil(holidayNote) ? holidayNote : '',
                            global_holiday: holidayScope,
                            status: isActive ? Status.ENABLE : Status.DISABLED,
                            weight: holidayWeight,
                            split_weekdays: isWeekDay ? 1 : 0,
                            date: [
                                {
                                    value: UiUtils.parseFullHolidayDate(startDate.toString()),
                                    inclusive: true
                                },
                                {
                                    value: UiUtils.parseFullHolidayDate(endDate.toString()),
                                    inclusive: true
                                }
                            ],
                            location_id: handleLocationByScope(holidayScope),
                        }]
                    }
                }
            }
            try {
                await addHolidayWithDetails(newHoliday).unwrap();
                setUiAlert(UiUtils.getSaveSuccessMessage());
                setTimeout(() => {
                    history.push(AppRoutes.HOME)
                }, 2000)
            } catch (error) {
                setUiAlert(UiUtils.getErrorMessage(error.data, error.status));
            }
        }
        if (type === HolidayFormOptions.NEW_DATE || type === HolidayFormOptions.EDIT) {
            const newDetail: { data: Partial<Detail> } = {
                data: {
                    type: 'holiday_details',
                    attributes: {
                        note: !_.isNil(holidayNote) ? holidayNote : '',
                        global_holiday: type === HolidayFormOptions.NEW_DATE ? data?.state['scope'] : currentData?.scope,
                        status: isActive ? Status.ENABLE : Status.DISABLED,
                        weight: holidayWeight,
                        split_weekdays: isWeekDay ? 1 : 0,
                        legacy_id: type === HolidayFormOptions.EDIT ? currentData?.legacy_id : null,
                        date: [
                            {
                                value: UiUtils.parseFullHolidayDate(startDate.toString()),
                                inclusive: true
                            },
                            {
                                value: UiUtils.parseFullHolidayDate(endDate.toString()),
                                inclusive: true
                            }
                        ],
                        location_id: type === HolidayFormOptions.NEW_DATE ? handleLocationByScope(data?.state['scope']) : handleLocationByScope(currentData?.scope),
                        holiday_id: +data?.state['holiday_id']
                    }
                }
            }
            if (type === HolidayFormOptions.NEW_DATE) {

                try {
                    await addHolidayDetail(newDetail).unwrap();
                    setUiAlert(UiUtils.getSaveSuccessMessage());
                    setTimeout(() => {
                        history.push(AppRoutes.HOME)
                    }, 2000)
                } catch (error) {
                    setUiAlert(UiUtils.getErrorMessage(error.data, error.status));
                }
            }
            if (type === HolidayFormOptions.EDIT) {
                try {
                    await updateHolidayDetail({id: currentData.key, body: newDetail}).unwrap()
                    setUiAlert(UiUtils.getSaveSuccessMessage());
                } catch (error) {
                    setUiAlert(UiUtils.getErrorMessage(error.data, error.status));
                }
            }
        }
    };
    const onDatesChange = (start: Date, end: Date) => {
        setStartDate(start);
        setEndDate(end);
    };
    
    const handleLocationByScope = (scope: HolidayScopeTypes) => {
        switch (scope) {
            case HolidayScopeTypes.GLOBAL:
                return null
            case HolidayScopeTypes.COUNTRY:
                return +holidayCountry.value
            case HolidayScopeTypes.REGIONAL:
                return +holidayRegion.value
            case HolidayScopeTypes.LOCAL:
                return +holidayCity.value
        }
    }
    const buttonLeft: ButtonProps = {
        onClick: () => history.push(AppRoutes.HOME),
        children: "Cancel",
        variant: "info",
        customClass: 'button-group'
    }

    const buttonRight: ButtonProps = {
        onClick: handleSave,
        children: "Save",
        variant: "secondary",
        disabled: isSaveDisabled,
        customClass: 'button-group'
    }

    useEffect(() => {
        if (!_.isEmpty(locations) && !isFetching) {
            setLocationsData(locations?.data)
        }
    }, [locations, isFetching])

    useEffect(() => {
        if (!_.isEmpty(locationData)) {
            if (type === HolidayFormOptions.EDIT) {
                setParentLocation(UiUtils.getParentLocations(locationData, currentData?.location_type, currentData?.location_id))
            }
        }
    }, [locationData, currentData, type])
    useEffect(() => {
        if (!_.isEmpty(parentLocation)) {
            if (currentData?.location_type === LocationTypes.REGION) {
                setHolidayCountry(parentLocation[0])
            }
            if (currentData?.location_type === LocationTypes.CITY) {
                setHolidayCountry(parentLocation[0])
                setHolidayRegion(parentLocation[1])
            }
        }
    })
    useEffect(() => {
        if (!_.isEmpty(locationData)) {
            setHolidayRegion(currentData.parent_id === holidayCountry?.value ? {
                value: currentData.location_id,
                display: currentData.location
            } : null);
        }
    }, [holidayCountry?.value, currentData, locationData])
    useEffect(() => {
        if (!_.isEmpty(locationData)) {
            setHolidayCity(currentData.parent_id === holidayRegion?.value ? {
                value: currentData.location_id,
                display: currentData.location
            } : null)
        }
    }, [holidayRegion?.value, currentData, locationData])
    useEffect(() => {
        if (holidayScope === HolidayScopeTypes.GLOBAL) {
            setHolidayCountry(null);
        }
        if (holidayScope === HolidayScopeTypes.COUNTRY) {
            setHolidayRegion(null);

        }
        if (holidayScope === HolidayScopeTypes.REGIONAL) {
            setHolidayCity(null);
        }
    }, [holidayScope])
    useEffect(() => {
        if (!isFetching) {
            if (type === HolidayFormOptions.ADD) {
                if (_.isNil(holidayName) || _.isEmpty(holidayName) || !UiUtils.isValidDateRange(startDate, endDate) || _.isNil(holidayScope) || _.isNil(holidayWeight) || (holidayScope === HolidayScopeTypes.COUNTRY && _.isNil(holidayCountry)) ||
                    (holidayScope === HolidayScopeTypes.REGIONAL && _.isNil(holidayRegion)) || (holidayScope === HolidayScopeTypes.LOCAL && _.isNil(holidayCity))) {
                    setUiAlert(null);
                    return setIsSaveDisabled(true);
                }

                setIsSaveDisabled(false)
                setUiAlert(UiUtils.getPendingChangesMessage());
            }
            if (type === HolidayFormOptions.EDIT) {
                if (_.isNil(holidayWeight) || !UiUtils.isValidDateRange(startDate, endDate) || (currentData?.scope === HolidayScopeTypes.COUNTRY && _.isNil(holidayCountry)) ||
                    (currentData?.scope === HolidayScopeTypes.REGIONAL && _.isNil(holidayRegion)) ||
                    (currentData?.scope === HolidayScopeTypes.LOCAL && _.isNil(holidayCity))) {
                    setUiAlert(null);
                    return setIsSaveDisabled(true);
                }

                if (holidayWeight !== currentData?.weight || UiUtils.parseDate(startDate.toString()) !== currentData?.first_night ||
                    UiUtils.parseDate(endDate.toString()) !== currentData?.last_night || holidayNote !== currentData?.note
                    || (currentData?.scope === HolidayScopeTypes.COUNTRY && holidayCountry?.value !== currentData?.location_id) ||
                    (currentData?.scope === HolidayScopeTypes.REGIONAL && holidayRegion?.value !== currentData?.location_id) ||
                    (currentData?.scope === HolidayScopeTypes.LOCAL && holidayCity?.value !== currentData?.location_id) ||
                    (isActive && currentData?.status !== Status.ENABLE) || (!isActive && currentData?.status !== Status.DISABLED) ||
                    (isWeekDay && currentData.split_weekdays !== 1) || (!isWeekDay && currentData.split_weekdays !== 0)) {
                    setUiAlert(UiUtils.getPendingChangesMessage());
                    return setIsSaveDisabled(false)

                }
                setUiAlert(null);
                setIsSaveDisabled(true);
            }
            if (type === HolidayFormOptions.NEW_DATE) {
                if ( !UiUtils.isValidDateRange(startDate, endDate) || _.isNil(holidayWeight) || (data?.state['scope'] === HolidayScopeTypes.COUNTRY && _.isNil(holidayCountry)) ||
                    (data?.state['scope'] === HolidayScopeTypes.REGIONAL && _.isNil(holidayRegion)) ||
                    (data?.state['scope'] === HolidayScopeTypes.LOCAL && _.isNil(holidayCity))) {
                    return setIsSaveDisabled(true);
                }
                setIsSaveDisabled(false)
                setUiAlert(UiUtils.getPendingChangesMessage());
            }
        }

    }, [isFetching, type, holidayName, holidayScope, holidayWeight, holidayCountry, holidayRegion, holidayCity, currentData, startDate, endDate, holidayNote, isWeekDay, isActive, data?.state]);
    return (
        <React.Fragment>
            <div className="holiday-form-top-bar">
                <Tooltip message="Holiday List">
                        <span className="add-holiday">
                            <Icon.CornerDownLeft className="pointer" height={24} width={24}
                                                 onClick={() => history.push(AppRoutes.HOME)}/>
                        </span>
                </Tooltip>
                <h5>{props.type === HolidayFormOptions.ADD ? 'Add New Holiday' : props.type === HolidayFormOptions.EDIT ? `Edit Holiday Date ${data?.state['name']}` : `Add New Date ${data?.state['name']} `}</h5>
                <div className={'top-bar-buttons'}>
                    <ButtonGroup left={buttonLeft} right={buttonRight}/>
                </div>
            </div>
            <div className={'alert-container'}>
                {uiAlert && <AlertMessage customClass="alert-message" text={uiAlert.content} type={uiAlert.type}
                                          height="small"/>}
            </div>
            <div className="container-info">
                {isFetching
                    ? <Loading className="holiday-form-loading"/>
                    : <div>
                        {props.type === HolidayFormOptions.ADD ? (
                            <div className='holiday_top_content'>
                                <div className='holiday_top_inputs'>
                                    <p>Holiday Name:</p>
                                    <Input
                                        customClass="inputs"
                                        type="text"
                                        value={holidayName}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHolidayName(e.target.value)}

                                    />
                                </div>
                                <div className={'top-select-config'}>
                                    <p>Scope:</p>
                                    <Select
                                        value={holidayScope}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHolidayScope(e.target.value as HolidayScopeTypes)}
                                        options={UiUtils.getScopeFilterOptions().slice(1, 5)}
                                        customClass={"select-format"}
                                    />
                                </div>
                                <div className='holiday_top_inputs'>
                                    <p>Description:</p>
                                    <Input
                                        customClass="inputs"
                                        type="text"
                                        value={holidayDescription}
                                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => setHolidayDescription(e.target.value)}
                                    />
                                </div>
                            </div>
                        ) : (
                            ''
                        )}

                        <div className={'holiday-form-fields'}>
                            <div className={'holiday-content-block1'}>
                                <div className="date-range-selector">
                                    <p>Date Range:</p>
                                    <DateRangePickers
                                        start={startDate}
                                        end={endDate}
                                        onDateRangeChange={onDatesChange}
                                    />
                                </div>
                                <div className="test-area-config">
                                    <p>Notes:</p>
                                    <textarea
                                        rows={5}
                                        maxLength={255}
                                        value={holidayNote}
                                        onChange={(event) => {
                                            setHolidayNote(event.target.value);
                                        }}
                                    />
                                </div>
                            </div>
                            <div className={'holiday-content-block2'}>
                                <div className={'select-group'}>
                                    <p>Country:</p>
                                    <AutoCompleteSelect
                                        key={holidayCountry?.value}
                                        value={holidayCountry}
                                        onChange={(newValue: SelectOption) => {
                                            setHolidayCountry(newValue)
                                            if (type === HolidayFormOptions.EDIT) {
                                                parentLocation.length = 0
                                            }
                                        }}
                                        options={isFetching || holidayScope === HolidayScopeTypes.GLOBAL || (type !== HolidayFormOptions.ADD ? currentData?.scope || data.state['scope'] : '') === HolidayScopeTypes.GLOBAL ? [] : UiUtils.getLocationOptions(locationData, LocationTypes.COUNTRY)}
                                        customClass={"select-size"}
                                        getOptionLabel={(holidayCountry) => holidayCountry.display}

                                    />
                                </div>
                                <div className={'select-group'}>
                                    <p>Region:</p>
                                    <AutoCompleteSelect
                                        key={holidayRegion?.value}
                                        value={holidayRegion}
                                        onChange={(newValue: SelectOption) => {
                                            setHolidayRegion(newValue);
                                            if (type === HolidayFormOptions.EDIT) {
                                                parentLocation.length = 0
                                            }

                                        }}
                                        options={isFetching || holidayScope === HolidayScopeTypes.COUNTRY || (type !== HolidayFormOptions.ADD ? currentData?.scope || data.state['scope'] : '') === HolidayScopeTypes.COUNTRY ? [] : UiUtils.getLocationOptions(locationData, LocationTypes.REGION, +holidayCountry?.value)}
                                        customClass={"select-size"}
                                        getOptionLabel={(holidayRegion) => holidayRegion.display}


                                    />
                                </div>
                                <div className={'select-group'}>
                                    <p>City: </p>
                                    <AutoCompleteSelect
                                        key={holidayCity?.value}
                                        value={holidayCity}
                                        onChange={(newValue: SelectOption) => setHolidayCity(newValue)}
                                        options={isFetching || holidayScope === HolidayScopeTypes.REGIONAL || (type !== HolidayFormOptions.ADD ? currentData?.scope || data?.state['scope'] : '') === HolidayScopeTypes.REGIONAL ? [] : UiUtils.getLocationOptions(locationData, LocationTypes.CITY, +holidayRegion?.value)}
                                        customClass={"select-size"}
                                        getOptionLabel={(holidayCity) => holidayCity.display}
                                    />
                                </div>
                            </div>
                            <div className={'holiday-content-block3'}>
                                <div className={'switch-group'}>
                                    <p>Weight:</p>
                                    <NumberInput
                                        className="holiday-name-input"
                                        decimals={true}
                                        value={holidayWeight}
                                        onChange={(holidayWeight) => setHolidayWeight(holidayWeight)}
                                        min={0}
                                        max={2}
                                    />
                                </div>
                                <div className={'switch-group'}>
                                    <p>Weekdays:</p>
                                    <Switch
                                        key={'weekday-' + isWeekDay ? 1 : 2}
                                        checked={isWeekDay}
                                        onClick={() => setIsWeekDay(!isWeekDay)}
                                    />
                                </div>
                                <div className={'switch-group'}>
                                    <p>Active:</p>
                                    <Switch
                                        key={'active-' + isActive ? 1 : 2}
                                        checked={isActive}
                                        onClick={() => setIsActive(!isActive)}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>
                }
            </div>
        </React.Fragment>
    );
};