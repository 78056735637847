import React, {ReactElement, useEffect, useState} from 'react';
import {Icon, Input, Select, Tooltip} from '@vacasa/react-components-lib';
import './FilterConfiguration.scss';
import {UiUtils} from "../../utils";
import {useGetAllLocationQuery} from "../../store";
import {filter, isEmpty} from 'lodash';
import {Location} from "../../types";
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';

interface FilterConfigurationProps {
    nameFilter: string;
    statusFilter: string;
    regionFilter: Location[];
    yearFilter: string
    scopeFilter: string;
    onChangeForName: (nameFilter) => void;
    onChangeForStatus: (statusFilter) => void;
    onChangeForRegion: (event, regionFilter) => void;
    onChangeForYear: (yearFilter) => void;
    onChangeForScope: (scopeFilter) => void;
}

type FilterConfigurationComponent = (props: FilterConfigurationProps) => ReactElement<any, any> | null;

export const FilterConfiguration: FilterConfigurationComponent = (props) => {
    const { nameFilter, statusFilter, regionFilter, scopeFilter, yearFilter , onChangeForName, onChangeForStatus, onChangeForRegion, onChangeForScope, onChangeForYear } = props;
    const {data: locations, isFetching} = useGetAllLocationQuery();
    const [regionsAndCities, setRegionsAndCities] = useState<Location[]>([]);

    useEffect(() => {
        if (!isEmpty(locations) && !isFetching) {
            const filteredLocations = filter(locations.data, (location) => location.attributes.type === 'REGION');
            setRegionsAndCities(() => filteredLocations);
        }
    }, [locations, isFetching])

    return (
            <div className="filters">
                <div className="filter name-filter">
                    <p>Name:</p>
                    <Input
                        value={nameFilter}
                        type="text"
                        onChange={onChangeForName}
                        customClass={"input-filter"}
                    />
                </div>


                <div className="filter status-filter">
                    <p>Status:</p>
                    <Select
                        value={statusFilter}
                        onChange={onChangeForStatus}
                        options={UiUtils.getStatusFilterOptions()}
                        customClass={"select-filter"}
                    />
                </div>


                <div className="filter region-filter">
                    <p>Region:</p>
                    <Autocomplete
                        className={"autocomplete-filter"}
                        multiple
                        options={regionsAndCities}
                        getOptionLabel={(option) => option.attributes.name}
                        filterSelectedOptions
                        value={regionFilter}
                        onChange={onChangeForRegion}
                        renderInput={(params) => {
                            return (
                            <TextField
                                {...params}
                                variant="outlined"
                            />
                        )}}
                    />
                </div>


                <div className="filter year-filter">
                    <p>Year:</p>
                    <Select
                        value={yearFilter}
                        onChange={onChangeForYear}
                        options={UiUtils.getHolidayYearOptions()}
                        customClass={"select-filter"}
                    />
                </div>


                <div className="filter scope-filter">
                    <p>Scope:</p>
                    <Select
                        value={scopeFilter}
                        onChange={onChangeForScope}
                        options={UiUtils.getScopeFilterOptions()}
                        customClass={"select-filter"}
                    />
                </div>

                <div className="filter reload-icon">
                    <Tooltip message="Reload">
                        <span className="reload">
                            <Icon.RotateCCW className="pointer" height={24} width={24} onClick={() => document.location.reload()}/>
                        </span>
                    </Tooltip>
                </div>

            </div>
    )
};