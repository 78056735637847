import React from 'react';
import { Tooltip } from '@material-ui/core';
import { Icon } from '@vacasa/react-components-lib';
import './SortableHeaderLabel.scss';

interface SortableHeaderLabelProps {
    label: string;
    field: string;
    order: string;
    onOrderChange: (field: string, order: string) => void;
}

export const SortableHeaderLabel: React.FC<SortableHeaderLabelProps> = (props) => {
    const { label, order, field, onOrderChange } = props;

    const handleSort = (event) => {
        event.preventDefault();
        onOrderChange(field, order);
    };

    return (
        <Tooltip title={order === 'asc' ? `Sort ${label} descending` : `Sort ${label} ascending`} arrow={true} placement={'top'}>
            <div className={'sortable-header pointer'} onClick={handleSort}>
                {order === 'desc' ? (
                    <Icon.ArrowDown className={'sort-icon'} height={12} width={12} />
                ) : (
                    <Icon.ArrowUp className={'sort-icon ' + (!order ? 'hidden' : null)} height={12} width={12} />
                )}
                {label}
            </div>
        </Tooltip>
    );
};
