import React from "react";
import {Icon, Tooltip, ButtonGroup} from "@vacasa/react-components-lib";
import {ButtonProps} from "@vacasa/react-components-lib/lib/components/Button/Button";
import "./FormHeader.scss";

interface IFormHeaderProps {
    title: string;
    backButtonTooltip: string;
    handleBack: () => void;
    handleCancel: () => void;
    handleSave: () => void;
    disabledSave: boolean;
}

export const FormHeader = ({
                               title,
                               backButtonTooltip,
                               handleBack,
                               handleCancel,
                               handleSave,
                               disabledSave
                           }: IFormHeaderProps) => {
    const cancelButton: ButtonProps = {
        onClick: handleCancel,
        children: "Cancel",
        variant: "info",
        customClass: 'button-group'
    }

    const saveButton: ButtonProps = {
        onClick: handleSave,
        children: "Save",
        variant: "secondary",
        disabled: disabledSave,
        customClass: 'button-group'
    }

    return (
        <div className="form-header-top-bar">
            <Tooltip message={backButtonTooltip}>
                <span className="back-button">
                    <Icon.CornerDownLeft className="pointer" height={24} width={24} onClick={handleBack}/>
                </span>
            </Tooltip>
            <h5>{title}</h5>
            <div className={'action-buttons'}>
                <ButtonGroup left={cancelButton} right={saveButton}/>
            </div>
        </div>
    )
}