import { nanoid } from 'nanoid';

const flowId = nanoid(10);

export enum StorageKeys {
    ACCESS_TOKEN = 'accessToken',
    ID_TOKEN = 'idToken',
}

export const getHeaders = (headers) => {
    const accessToken = localStorage.getItem(StorageKeys.ACCESS_TOKEN);
    const idToken = localStorage.getItem(StorageKeys.ID_TOKEN);
    headers.set('Content-Type', 'application/vnd.api+json');
    headers.set('Authorization', `Bearer ${accessToken}`);
    headers.set('x-identity-token', idToken);
    headers.set('x-flow-id', flowId);
    return headers;
};