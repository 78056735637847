import {apiHoliday} from "./api";
import {Detail} from "../types";

export const holidayDetailsApi = apiHoliday.injectEndpoints({
    endpoints: (builder) => ({
        deleteHolidayDetail: builder.mutation({
            query: (id) => {
                return {
                    url: `holiday-detail/${id}`,
                    method: 'DELETE',
                };
            },
            invalidatesTags: [{type: "Holidays", id: "holidayList"}]
        }),
        updateHolidayDetail: builder.mutation<Detail, {id: number, body: {data: Partial<Detail> } }>({
            query: ({id, body})=>({
                url: `holiday-detail/${+id}`,
                method: "PUT",
                body: JSON.stringify(body)
            }),
            invalidatesTags:[{type: 'HolidayDetails',id: 'HolidayDetailList'}]
        }),
        addHolidayDetail: builder.mutation<Detail,  {data: Partial<Detail>} >({
            query: (body) => ({
                url: "holiday-detail",
                method: "POST",
                body: JSON.stringify(body)
            }),
            invalidatesTags: [{type: 'HolidayDetails',id: 'HolidayDetailList'}]
        }),
    }),
    overrideExisting: false,
})

export const { useDeleteHolidayDetailMutation, useAddHolidayDetailMutation,useUpdateHolidayDetailMutation  } = holidayDetailsApi;
