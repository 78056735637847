import {useCallback, useEffect, useState} from "react";
import {isEmpty, isEqual} from "lodash";
import {UiUtils} from "../../utils";
import {HolidayScopeTypes, LocationTypes} from "@common/types";
import {HolidayDetailInformationType, HolidayDetailsValue, Location, SelectOption} from "../../types";
import {DateRangePickers} from "../DateRangePickers/DateRangePickers";
import {AutoCompleteSelect} from "@vacasa/react-components-lib";
import {NumberInput} from "../NumberInput/NumberInput";
import {Switch} from "@material-ui/core";
import "./HolidayDetailInformation.scss";
import {Loading} from "../Loading/Loading";

interface IHolidayDetailInformationProps {
    holidayScope: HolidayScopeTypes;
    holiday_detail: HolidayDetailsValue;
    locations: any;
    onChangeDetail: (data, key) => void;
    invalidDetail: boolean;
}

export const HolidayDetailInformation = ({
                                             holidayScope,
                                             holiday_detail,
                                             locations,
                                             onChangeDetail,
                                             invalidDetail
                                         }: IHolidayDetailInformationProps) => {
    const {key, location_id, location_type} = holiday_detail;
    const [locationData, setLocationData] = useState<Location[]>()
    const [locationWithParents, setLocationWithParents] = useState<{ value: number, display: string, type: string }[]>();
    const [holidayDetailData, setHolidayDetailData] = useState<HolidayDetailInformationType | null>(null);
    const [originalData, setOriginalData] = useState<HolidayDetailInformationType | null>();
    const getDetailData = useCallback((holiday_detail: HolidayDetailsValue) => UiUtils.getHolidayDetailInformationType(holiday_detail), [holiday_detail, holidayScope])

    useEffect(() => {
        const detailData: HolidayDetailInformationType = getDetailData(holiday_detail);
        setOriginalData(() => detailData);
        setHolidayDetailData(() => detailData);
    }, [holiday_detail])

    useEffect(() => {
        if (!isEmpty(locations)) {
            setLocationData(locations?.data)
        }
    }, [locations])

    useEffect(() => {
        if (!isEmpty(locationData)) {
            setLocationWithParents(UiUtils.getLocationWithParents(locationData, location_type as LocationTypes, location_id))
        }
    }, [locationData, location_type, location_id])

    useEffect(() => {
        if (!isEmpty(locationWithParents)) {
            if (location_type === LocationTypes.COUNTRY) {
                const country = locationWithParents[0];
                setHolidayDetailData((prev) => ({...prev, country}))
                setOriginalData((prev) => ({...prev, country}))
            }
            if (location_type === LocationTypes.REGION) {
                const country = locationWithParents[0];
                const region = locationWithParents[1];
                setHolidayDetailData((prev) => ({...prev, country, region}))
                setOriginalData((prev) => ({...prev, country, region}))
            }
            if (location_type === LocationTypes.CITY) {
                const country = locationWithParents[0];
                const region = locationWithParents[1];
                const city = locationWithParents[2];
                setHolidayDetailData((prev) => ({...prev, country, region, city}))
                setOriginalData((prev) => ({...prev, country, region, city}))
            }
        }
    }, [locationWithParents, location_type])

    useEffect(() => {
        const detailData: HolidayDetailInformationType = getDetailData(holiday_detail);
        setHolidayDetailData(() => detailData);
        if (!isEmpty(originalData) && !isEqual(originalData, detailData)) {
            onChangeDetail(detailData, holiday_detail.key);
        }
    }, [holidayScope])

    const onDatesChange = (start: Date, end: Date) => {
        handleChange("dates", {start_date: start, end_date: end});
    };

    const handleChange = (key: keyof HolidayDetailInformationType, value: { start_date: Date, end_date: Date} | string | SelectOption | number | boolean, cleanFields?: string[]) => {
        const newHolidayDetailData = {...holidayDetailData, [key]: value};
        if (cleanFields) {
            cleanFields.forEach((cleanField) => {
                newHolidayDetailData[cleanField] = null;
            })
        }
        if (isEqual(originalData, newHolidayDetailData)) {
            onChangeDetail(null, holiday_detail.key);
        } else {
            onChangeDetail(newHolidayDetailData, holiday_detail.key);
        }
        setHolidayDetailData(newHolidayDetailData);
    }

    return (
        <div className={`holiday-detail-information-container ${invalidDetail ? "invalid-holiday-detail" : ""}`}
             key={key}>
            {holidayDetailData === null ? <Loading className="holiday-detail-loading"/> :
                (<>
                    <div className={'content-block-1'}>
                        <div className="date-range-selector">
                            <p>Date Range:</p>
                            <DateRangePickers
                                start={holidayDetailData.dates.start_date}
                                end={holidayDetailData.dates.end_date}
                                onDateRangeChange={onDatesChange}
                            />
                        </div>
                        <div className="text-area-config">
                            <p>Notes:</p>
                            <textarea
                                rows={5}
                                maxLength={255}
                                value={holidayDetailData.notes}
                                onChange={(event) => {
                                    handleChange("notes", event.target.value)
                                }}
                            />
                        </div>
                    </div>
                    <div className={'content-block-2'}>
                        <div className={'select-group'}>
                            <p>Country:</p>
                            <AutoCompleteSelect
                                value={holidayDetailData.country}
                                onChange={(newValue: SelectOption) => {
                                    handleChange("country", newValue, ["region", "city"]);
                                }}
                                options={(holidayScope === HolidayScopeTypes.GLOBAL) ? [] : UiUtils.getLocationOptions(locationData, LocationTypes.COUNTRY)}
                                customClass={"select-size"}
                                getOptionLabel={(holidayCountry) => holidayCountry.display}
                            />
                        </div>
                        <div className={'select-group'}>
                            <p>Region:</p>
                            <AutoCompleteSelect
                                value={holidayDetailData.region}
                                onChange={(newValue: SelectOption) => {
                                    handleChange("region", newValue, ["city"]);
                                }}
                                options={(holidayScope === HolidayScopeTypes.COUNTRY) ? [] : UiUtils.getLocationOptions(locationData, LocationTypes.REGION, +holidayDetailData.country?.value)}
                                customClass={"select-size"}
                                getOptionLabel={(holidayRegion) => holidayRegion.display}


                            />
                        </div>
                        <div className={'select-group'}>
                            <p>City: </p>
                            <AutoCompleteSelect
                                value={holidayDetailData.city}
                                onChange={(newValue: SelectOption) => handleChange("city", newValue)}
                                options={(holidayScope === HolidayScopeTypes.REGIONAL) ? [] : UiUtils.getLocationOptions(locationData, LocationTypes.CITY, +holidayDetailData.region?.value)}
                                customClass={"select-size"}
                                getOptionLabel={(holidayCity) => holidayCity.display}
                            />
                        </div>
                    </div>
                    <div className={'content-block-3'}>
                        <div className={'switch-group'}>
                            <p>Weight:</p>
                            <NumberInput
                                className="holiday-name-input"
                                decimals={true}
                                value={holidayDetailData.weight}
                                onChange={(holidayWeight) => handleChange("weight", holidayWeight)}
                                min={0}
                                max={2}
                            />
                        </div>
                        <div className={'switch-group'}>
                            <p>Weekdays:</p>
                            <Switch
                                key={'weekday-' + holidayDetailData.weekdays ? 1 : 2}
                                checked={holidayDetailData.weekdays}
                                onClick={() => handleChange("weekdays", !holidayDetailData.weekdays)}
                            />
                        </div>
                        <div className={'switch-group'}>
                            <p>Active:</p>
                            <Switch
                                key={'active-' + holidayDetailData.active ? 1 : 2}
                                checked={holidayDetailData.active}
                                onClick={() => handleChange("active", !holidayDetailData.active)}
                            />
                        </div>
                    </div>
                </>)}
        </div>

    )
}