import React, {useState} from 'react';
import {Paper} from '@material-ui/core';
import {Tabs} from '@vacasa/react-components-lib';
import './Home.scss';
import {ChangeLog, HolidayList} from "../../components";

export const Home: React.FC = () => {
    const [selectedTab, setSelectedTab] = useState<number>(0);

    return (
        <Paper elevation={3} className="home">
            <div className="tabs-container">
                <Tabs
                    selected={selectedTab}
                    tabs={[
                        {
                            id: "holidays",
                            label: "Holiday List",
                            component: <HolidayList />
                        },
                        {
                            id: "changelog",
                            label: "Change Log",
                            component: <ChangeLog />
                        }
                    ]}
                    onChange={(index) => setSelectedTab(index)}
                />
            </div>
        </Paper>
    );
};