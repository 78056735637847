import React from 'react';
import {Paper} from '@material-ui/core';
import './NewHoliday.scss';
import {HolidayForm} from "../../components/HolidayForm/HolidayForm";
import {HolidayFormOptions} from "../../types";

export const NewHoliday: React.FC = () => {

    return (
        <React.Fragment>
            <Paper elevation={3} className="new-holiday">
                <HolidayForm type={HolidayFormOptions.ADD}/>
            </Paper>
        </React.Fragment>
    );
};