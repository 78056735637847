import React from 'react';
import {HolidayForm} from "../../components/HolidayForm/HolidayForm";
import {HolidayFormOptions} from "../../types";
import {Paper} from '@material-ui/core';
import './EditHolidayDetail.scss';

export const EditHolidayDetail: React.FC = () => {
    return (
        <React.Fragment>
            <Paper elevation={3} className="edit-holiday-detail">
                <HolidayForm type={HolidayFormOptions.EDIT}/>
            </Paper>
        </React.Fragment>
    );
};