import {
    DATE_FORMAT,
    FULL_DATE,
    HolidayScopeTypes,
    LocationTypes,
    OnlyNumbersRegex,
    Status,
    TwoDecimalNumberRegex
} from '@common/types';
import {filter, forEach, head, isEmpty, isNaN, isNil, isNumber, map, sortBy, uniq} from 'lodash';
import {
    ChangeLogValues,
    Holiday,
    HolidayAttributes,
    HolidayDetail,
    HolidayDetailInformationType,
    HolidayDetailsCurrentScope,
    HolidayDetailsCurrentStatus,
    HolidayDetailsValue,
    HolidayListValues,
    LocationAttributes,
    Message,
    ScopeOptions,
    SelectOption,
    StatusOptions
} from "../types";
import {addDays, format, getYear, isAfter, isEqual, isValid, parse, startOfDay} from "date-fns";

const statusByOption: { [key in StatusOptions]: HolidayDetailsCurrentStatus[] } = {
    All: ['Active', 'Inactive'],
    Active: ['Active'],
    Inactive: ['Inactive']
};

const scopeByOption: { [key in ScopeOptions]: HolidayDetailsCurrentScope[] } = {
    All: ["GLOBAL", "COUNTRY", "REGIONAL", "LOCAL"],
    GLOBAL: ['GLOBAL'],
    COUNTRY: ['COUNTRY'],
    REGIONAL: ['REGIONAL'],
    LOCAL: ['LOCAL']
};

export class UiUtils {

    public static colorStatus = (status) => {
        switch (status) {
            case "ENABLE":
                return 'success';
            case "DISABLED":
                return 'error';
            default:
                return 'info';
        }
    };

    public static getTextForBadge = (string) => {
        switch (string) {
            case "ENABLE":
                return 'Active';
            case "DISABLED":
                return 'Inactive';
            default:
                return '';
        }
    }

    public static getHolidayYearOptions = () => {
        const currentYear = getYear(new Date());
        const threeYearsFromNow = currentYear + 3;
        const holidayYearOptions = [];
        let year = currentYear - 15; // nine years ago;
        holidayYearOptions.push({value: "All", display: "All"});
        while (year <= threeYearsFromNow) {
            holidayYearOptions.push({value: year.toString(), display: year.toString()});
            year++;
        }
        return holidayYearOptions;
    };

    public static isFloat(val: string): boolean {
        const asNumber = Number(val);
        const isNumeric = isNumber(asNumber) && !isNaN(asNumber);
        return isNumeric && (OnlyNumbersRegex.test(val) || TwoDecimalNumberRegex.test(val));
    }

    public static isInt(val: string): boolean {
        const asNumber = Number(val);
        const isNumeric = isNumber(asNumber) && !isNaN(asNumber);
        return isNumeric && OnlyNumbersRegex.test(val);
    }

    public static getStatusFilterOptions = () => map(statusByOption, (value, key) => ({value: key, display: key}));

    public static getScopeFilterOptions = () => map(scopeByOption, (value, key) => ({value: key, display: key}));

    public static parseDate(dateString: string) {
        return format(new Date(new Date(dateString).valueOf() + new Date(dateString).getTimezoneOffset() * 60 * 1000), DATE_FORMAT);
    }

    public static parseFullHolidayDate(dateString: string) {
        return format(new Date(new Date(dateString).valueOf() + new Date(dateString).getTimezoneOffset() * 60 * 1000), FULL_DATE);
    }

    public static transformStringToDate(dateString: string) {
        return parse(dateString, DATE_FORMAT, new Date());
    }

    public static getHolidayListWithDetailValues(holidays): HolidayListValues[] {
        holidays = sortBy(holidays, (holiday) => holiday.attributes.name);
        const holidayListValues: HolidayListValues[] = [];
        forEach(holidays, (holiday) => {
            const holidayData = {
                key: `${holiday.id}`,
                id: holiday.attributes.id,
                holiday_name: holiday.attributes.name,
                record: holiday.attributes.holiday_details?.length,
                holiday_details: [],
                scope: holiday.attributes.scope,
                description: holiday.attributes.description
            };
            forEach(holiday.attributes.holiday_details, (holidayDetail) => {
                if (!isEmpty(holidayDetail)) {
                    const holidayDetailsValue: HolidayDetailsValue = {
                        key: `${holidayDetail.id}`,
                        location: holidayDetail.location?.name,
                        scope: holiday.attributes.scope,
                        weight: holidayDetail.weight,
                        split_weekdays: holidayDetail.split_weekdays,
                        status: holidayDetail.status,
                        location_id: holidayDetail.location?.id,
                        legacy_id: holidayDetail.legacy_id,
                        note: holidayDetail.note,
                        parent_id: holidayDetail.location?.parent_id,
                        location_type: holidayDetail.location?.type,
                        first_night: holidayDetail.date[0]?.value && isValid(new Date(holidayDetail.date[0].value)) ? UiUtils.parseDate(holidayDetail.date[0]?.value) : '',
                        last_night: holidayDetail.date[1]?.value && isValid(new Date(holidayDetail.date[1].value)) ? UiUtils.parseDate(holidayDetail.date[1].value) : ''
                    }
                    holidayData.holiday_details.push(holidayDetailsValue);
                }
                holidayData.holiday_details.sort(function (a, b) {
                    return new Date(b.first_night).getTime() - new Date(a.first_night).getTime();
                });
            })
            holidayListValues.push(holidayData);
        });
        return holidayListValues;
    }

    public static isValidDateRange(start: Date, end: Date): boolean {
        const validDates = (start) && isValid(end);
        const startOfFirstDate = startOfDay(start);
        const startOfLastDate = startOfDay(end);
        const isSameDate = isEqual(startOfFirstDate, startOfLastDate);
        const isEndAfterStart = isAfter(startOfDay(end), startOfDay(start));
        return validDates && (isSameDate || isEndAfterStart);
    }

    public static getLocationOptions(locations, type: LocationTypes, parentId?: number): SelectOption[] {
        const locationOptions: SelectOption[] = []
        uniq(map(locations, (c) => {
            if (type === LocationTypes.COUNTRY && c.attributes.type === LocationTypes.COUNTRY) {
                locationOptions.push({value: c.attributes.id, display: c.attributes.name})
            }
            if (parentId === c.attributes.parent_id && c.attributes.type !== LocationTypes.COUNTRY) {
                locationOptions.push({value: c.attributes.id, display: c.attributes.name})
            }
        }))
        return locationOptions;
    }

    public static getParentLocations(locations, type: LocationTypes, id): { value: number, display: string, type: string }[] {
        const countries = filter(locations, (l) => l.attributes.type === LocationTypes.COUNTRY)
        const regions = filter(locations, (l) => l.attributes.type === LocationTypes.REGION)
        const cities = filter(locations, (l) => l.attributes.type === LocationTypes.CITY)
        if (type === LocationTypes.REGION) {
            const region = head(filter(regions, (r) => r.attributes.id === id));
            const country = head(filter(countries, (c) => c.attributes.id === region.attributes.parent_id));
            return [{value: country.attributes.id, display: country.attributes.name, type: LocationTypes.COUNTRY}]
        }
        if (type === LocationTypes.CITY) {
            const city = head(filter(cities, (c) => c.attributes.id === id));
            const region = head(filter(regions, (r) => r.attributes.id === city.attributes.parent_id));
            const country = head(filter(countries, (c) => c.attributes.id === region.attributes.parent_id));
            return [{
                value: country?.attributes.id,
                display: country?.attributes.name,
                type: LocationTypes.COUNTRY
            }, {value: region?.attributes.id, display: region?.attributes.name, type: LocationTypes.REGION}]
        }
        return [];
    }

    public static getLocationWithParents(locations, type: LocationTypes, id): { value: number, display: string, type: string }[] {
        const countries = filter(locations, (l) => l.attributes.type === LocationTypes.COUNTRY)
        const regions = filter(locations, (l) => l.attributes.type === LocationTypes.REGION)
        const cities = filter(locations, (l) => l.attributes.type === LocationTypes.CITY)
        if (type === LocationTypes.COUNTRY) {
            const country = head(filter(countries, (c) => c.attributes.id === id));
            return [
                {value: country.attributes.id, display: country.attributes.name, type: LocationTypes.COUNTRY}
            ]
        }
        if (type === LocationTypes.REGION) {
            const region = head(filter(regions, (r) => r.attributes.id === id));
            const country = head(filter(countries, (c) => c.attributes.id === region.attributes.parent_id));
            return [
                {value: country.attributes.id, display: country.attributes.name, type: LocationTypes.COUNTRY},
                {value: region?.attributes.id, display: region?.attributes.name, type: LocationTypes.REGION}
            ]
        }
        if (type === LocationTypes.CITY) {
            const city = head(filter(cities, (c) => c.attributes.id === id));
            const region = head(filter(regions, (r) => r.attributes.id === city.attributes.parent_id));
            const country = head(filter(countries, (c) => c.attributes.id === region.attributes.parent_id));
            return [
                {value: country?.attributes.id, display: country?.attributes.name, type: LocationTypes.COUNTRY},
                {value: region?.attributes.id, display: region?.attributes.name, type: LocationTypes.REGION},
                {value: city?.attributes.id, display: city?.attributes.name, type: LocationTypes.CITY}
            ]
        }
        return [];
    }

    public static getHolidayDetailValues(holidayDetails: HolidayDetail[], scope: HolidayScopeTypes): HolidayDetailsValue[] {
        return map(holidayDetails, (holidayDetail) => {
            const {
                id: location_id,
                name: location,
                type: location_type,
                parent_id
            } = holidayDetail.location as LocationAttributes ?? {} as LocationAttributes;
            return ({
                key: holidayDetail.id.toString(),
                location,
                scope,
                weight: holidayDetail.weight,
                split_weekdays: holidayDetail.split_weekdays,
                status: holidayDetail.status,
                first_night: holidayDetail.date[0]?.value && isValid(new Date(holidayDetail.date[0].value)) ? UiUtils.parseDate(holidayDetail.date[0]?.value) : '',
                last_night: holidayDetail.date[1]?.value && isValid(new Date(holidayDetail.date[1].value)) ? UiUtils.parseDate(holidayDetail.date[1].value) : '',
                location_id,
                note: holidayDetail.note,
                location_type,
                parent_id,
                legacy_id: holidayDetail.legacy_id,
            })
        });
    }

    public static getChangeLogValues(changeLogs): ChangeLogValues[] {
        const changeLogValues: ChangeLogValues[] = [];
        forEach(changeLogs.data, (changeLog) => {
            if (!isEmpty(changeLog)) {
                const changeLogValue: ChangeLogValues = {
                    key: changeLog.attributes.id,
                    change_date: format(new Date(changeLog.attributes.created_at), FULL_DATE),
                    holiday: changeLog.attributes.holiday?.name,
                    analyst_name: changeLog.attributes.analyst_name,
                    change_type: changeLog.attributes.change_source,
                    change_details: changeLog.attributes.change_detail
                }
                changeLogValues.push(changeLogValue);
            }
        })
        return changeLogValues;
    }

    public static getLocationId(scope: HolidayScopeTypes, holidayCountry: SelectOption | null, holidayRegion: SelectOption | null, holidayCity: SelectOption | null, actualLocationId: number = null): number | null {
        switch (scope) {
            case HolidayScopeTypes.GLOBAL:
                return null
            case HolidayScopeTypes.COUNTRY:
                return +holidayCountry?.value ?? actualLocationId
            case HolidayScopeTypes.REGIONAL:
                return +holidayRegion?.value ?? actualLocationId
            case HolidayScopeTypes.LOCAL:
                return +holidayCity?.value ?? actualLocationId
        }
    }

    public static isValidHolidayDetail(holidayDetail: HolidayDetailInformationType, scope: HolidayScopeTypes): boolean {
        let validHolidayDetail = true;
        switch (scope) {
            case HolidayScopeTypes.GLOBAL:
                if (!isEmpty(holidayDetail.country) || !isEmpty(holidayDetail.region) || !isEmpty(holidayDetail.city)) {
                    validHolidayDetail = false;
                }
                break;
            case HolidayScopeTypes.COUNTRY:
                if (isEmpty(holidayDetail.country) || !isEmpty(holidayDetail.region) || !isEmpty(holidayDetail.city)) {
                    validHolidayDetail = false;
                }
                break;
            case HolidayScopeTypes.REGIONAL:
                if (isEmpty(holidayDetail.country) || isEmpty(holidayDetail.region) || !isEmpty(holidayDetail.city)) {
                    validHolidayDetail = false;
                }
                break;
            case HolidayScopeTypes.LOCAL:
                if (isEmpty(holidayDetail.country) || isEmpty(holidayDetail.region) || isEmpty(holidayDetail.city)) {
                    validHolidayDetail = false;
                }
                break;
            default:
                break;
        }
        return validHolidayDetail;
    }

    public static getInvalidHolidayDetailInformationType(holidayDetails: { [key: string]: HolidayDetailInformationType }, scope: HolidayScopeTypes): { [key: string]: HolidayDetailInformationType } | null {
        let invalidHolidayDetails: { [key: string]: HolidayDetailInformationType } = {};
        forEach(holidayDetails, (holidayDetail, index) => {
            if (!this.isValidHolidayDetail(holidayDetail, scope)) {
                invalidHolidayDetails = {...invalidHolidayDetails, [index]: holidayDetail}
            }
        });
        return !isEmpty(invalidHolidayDetails) ? invalidHolidayDetails : null;
    }

    public static getHolidayDetailInformationType(holidayDetail: HolidayDetailsValue): HolidayDetailInformationType {
        const {
            weight,
            split_weekdays,
            status,
            first_night,
            last_night,
            note,
            legacy_id
        } = holidayDetail;


        return ({
            legacy_id,
            dates: {start_date: !isNil(first_night) ? this.transformStringToDate(first_night) : new Date(), end_date: !isNil(last_night) ? this.transformStringToDate(last_night) : addDays(new Date(), 1)},
            notes: note,
            country: null,
            region: null,
            city: null,
            weight: !isNil(weight) ? weight : null,
            weekdays: !isNil(split_weekdays) && split_weekdays === 1,
            active: !isNil(status) && status === Status.ENABLE,
        });
    }

    public static getInvalidHolidayDetailsValue(holidayDetails: HolidayDetailsValue[], scope: HolidayScopeTypes, newHolidayDetails: { [key: string]: HolidayDetailInformationType }): { [key: string]: HolidayDetailInformationType } | null {
        let invalidHolidayDetails: { [key: string]: HolidayDetailInformationType } = {};
        forEach(holidayDetails, (holidayDetail) => {
            if (newHolidayDetails[holidayDetail.key]) return;
            let isInvalid = false;
            if (scope === HolidayScopeTypes.GLOBAL && holidayDetail.location_id) {
                isInvalid = true;
            }
            if (scope === HolidayScopeTypes.COUNTRY && (!holidayDetail.location_id || holidayDetail.location_type !== "COUNTRY")) {
                isInvalid = true;
            }
            if (scope === HolidayScopeTypes.REGIONAL && (!holidayDetail.location_id || holidayDetail.location_type !== "REGION")) {
                isInvalid = true;
            }
            if (scope === HolidayScopeTypes.LOCAL && (!holidayDetail.location_id || holidayDetail.location_type !== "CITY")) {
                isInvalid = true;
            }
            if(!this.isValidDateRange(this.transformStringToDate(holidayDetail.first_night), this.transformStringToDate(holidayDetail.last_night))){
                isInvalid = true
            }

            if (isInvalid) {
                invalidHolidayDetails = {
                    ...invalidHolidayDetails,
                    [holidayDetail.key]: this.getHolidayDetailInformationType(holidayDetail)
                }
            }
        });
        return !isEmpty(invalidHolidayDetails) ? invalidHolidayDetails : null;
    }

    public static getEditHolidayPayload(actualHoliday: HolidayAttributes, holiday: Partial<HolidayAttributes>, holidayDetails: { [key: string]: HolidayDetailInformationType }): Holiday {
        return ({
            type: "holiday",
            attributes: {
                id: actualHoliday.id,
                legacy_id: actualHoliday.legacy_id,
                name: holiday.name.trim(),
                scope: holiday.scope as HolidayScopeTypes,
                description: holiday.description.trim(),
                holiday_details: map(holidayDetails, (holidayDetail: HolidayDetailInformationType, key) => ({
                    id: +key,
                    legacy_id: holidayDetail.legacy_id,
                    note: holidayDetail.notes?.trim() ?? '',
                    global_holiday: holiday.scope as HolidayScopeTypes,
                    status: holidayDetail.active ? Status.ENABLE : Status.DISABLED,
                    weight: holidayDetail.weight,
                    split_weekdays: holidayDetail.weekdays ? 1 : 0,
                    date: [
                        {
                            value: this.parseFullHolidayDate(holidayDetail.dates.start_date.toString()),
                            inclusive: true
                        },
                        {
                            value: this.parseFullHolidayDate(holidayDetail.dates.end_date.toString()),
                            inclusive: true
                        }
                    ],
                    holiday_id: actualHoliday.id,
                    location_id: this.getLocationId(holiday.scope as HolidayScopeTypes, holidayDetail.country, holidayDetail.region, holidayDetail.city)
                })),
            },
        });
    }

    public static getSaveSuccessMessage = (): Message => ({
        type: 'success',
        content: `Changes submitted successfully. They may take a while to reflect`
    });
    public static getPendingChangesMessage = (): Message => ({type: 'warning', content: 'You have unsaved changes'});
    public static getErrorMessage = (error, status): Message => ({
        type: 'error',
        content: `An error occurred status: ${status}, message:${map(error, (e) => e.detail.error).toString()}`
    });

}
