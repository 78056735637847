import React, { KeyboardEvent, useEffect, useState } from 'react';
import { Tooltip } from '@vacasa/react-components-lib';
import * as _ from 'lodash';
import './NumberInput.scss';
import { OnlyNumbersRegex, TwoDecimalNumberRegex } from '@common/types';

interface NumberInputProps {
    value: number;
    onChange: (value: number) => void;
    onKeyDown?: (event: KeyboardEvent) => void;
    decimals?: boolean;
    min?: number;
    max?: number;
    className?: string;
    placeholder?: string;
    disable?: boolean;
    tooltip?: string;
    originalValue?: string;
    invalid?: boolean;
}

export const NumberInput = React.forwardRef<HTMLInputElement, NumberInputProps>((props, ref) => {
    const { value, onChange, decimals, min, max, className, onKeyDown, placeholder, disable, tooltip, originalValue, invalid } = props;
    const [stringValue, setStringValue] = useState<string>('');
    useEffect(() => {
        const asString = _.isNaN(value) ? '' : _.toString(value);
        setStringValue(asString);
    }, [value]);

    const handleOnChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let newValue = e.target.value;
        if (decimals) {
            if (newValue === '0.' || newValue === '0.0' || newValue === '0.00') {
                setStringValue(newValue);
                return;
            }
            if (newValue.startsWith('.')) {
                newValue = '0' + newValue;
                setStringValue(newValue);
                return;
            }
        }
        const valueAsNumber = Number(newValue);

        if (_.isEmpty(newValue)) {
            setStringValue('');
            onChange(null);
            return;
        }

        if ((!_.isUndefined(min) && valueAsNumber < min) || (!_.isUndefined(max) && valueAsNumber > max)) {
            return;
        }

        const isNumeric = _.isNumber(valueAsNumber) && !_.isNaN(valueAsNumber);
        const isValidNumber = isNumeric && OnlyNumbersRegex.test(newValue);
        const isValidDecimalNumber = isNumeric && TwoDecimalNumberRegex.test(newValue);

        if (newValue === '-') {
            setStringValue('-');
        }

        if (isValidNumber || (decimals && isValidDecimalNumber)) {
            setStringValue(newValue.trim());
            onChange(valueAsNumber);
        }
    };

    return (
        <Tooltip message={tooltip || ''}>
            <input
                className={
                    stringValue !== originalValue
                        ? `number-input changed ${className ?? ''} ${invalid ? 'error' : ''}`
                        : `number-input ${className ?? ''} ${invalid ? 'error' : ''}`
                }
                type="string"
                value={stringValue}
                onChange={handleOnChange}
                onKeyDown={onKeyDown}
                ref={ref}
                placeholder={placeholder}
                disabled={disable}
            />
        </Tooltip>
    );
});