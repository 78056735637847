import React from 'react';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';
import { Routes } from './Routes';
import { Container } from '@material-ui/core';
import { ThemeProvider, ImplicitFlow } from '@vacasa/react-components-lib';
import theme from '@vacasa/react-components-lib/lib/themes/default';
import { Configuration } from './Configuration';
import { datadogLogs } from '@datadog/browser-logs';
import './App.scss';
import {store} from './store';
import {Provider} from "react-redux";

const {
    REACT_APP_DD_CLIENT_TOKEN: clientToken,
    REACT_APP_CUSTOM_NODE_ENV: environment,
    REACT_APP_CUSTOM_NODE_ENV_SHORT: env,
    REACT_APP_VERSION: app_version,
} = process.env;

export const history = createBrowserHistory();

if (env !== 'local') {
    datadogLogs.init({
        clientToken,
        forwardErrorsToLogs: true,
        env,
    });

    datadogLogs.setLoggerGlobalContext({
        team: 'acrobatic-wizards',
        service: 'holiday-manager-app',
        env,
        environment,
        app_version,
    });
}

function App() {
  const AppContainer = () => {
    return (
        <ThemeProvider theme={theme}>
          <Container maxWidth={false}>
            <Router history={history}>
              <Routes />
            </Router>
          </Container>
        </ThemeProvider>
    );
  };

  return (
      <ImplicitFlow {...Configuration.getIDPConfiguration()}>
        <Provider store={store}>
          <AppContainer />
        </Provider>
      </ImplicitFlow>
  );
}

export default App;
