import {ChangesHistory} from "../types";
import {apiHoliday} from "./api";

export const changeHistoryApi = apiHoliday.injectEndpoints({
    endpoints: (builder) => ({
        getAllChangesHistory: builder.query<ChangesHistory[], void>({
            query: () => "changes-history",
            providesTags: [{type: "ChangesHistory", id: "changesHistory"}]
        })
    }),
    overrideExisting: false,
})

export const { useGetAllChangesHistoryQuery } = changeHistoryApi;