import React from 'react';
import {Button, Icon} from '@vacasa/react-components-lib';
import './DeleteContent.scss';
import {useDeleteHolidayDetailMutation} from "../../store/holiday-details";
import {Message} from "../../types";

interface DeleteContentProps {
    title: string;
    description: string;
    holidayDetailId: number;
    handeClose: () => void;
    handleUiAlert: (message: Message) => void;
}
export const DeleteContent: React.FC<DeleteContentProps> = (props) => {
    const { title, description, holidayDetailId, handeClose, handleUiAlert } = props;

    let [deleteHolidayDetail, {isLoading}] = useDeleteHolidayDetailMutation();

    const handleClickDelete = async (holidayDetailId: number) => {
        try {
            await deleteHolidayDetail(holidayDetailId).unwrap();
            handleUiAlert({type:'success',content:'Holiday detail was eliminated'});
        }catch (e){
            handleUiAlert({type:'error',content:'Holiday detail could not be eliminate'});
        }
        handeClose();
    }

    return (
        <>
            <div className="vacasa-modal-header">
                <p>{title}</p>
                <Icon.XCircleInverse className="pointer icon-opacity" height={24} width={24} onClick={handeClose} />
            </div>
            <div className="vacasa-modal-body">{description}</div>
            <div className="vacasa-modal-body">{"Note: Holiday Details can be set to Inactive"}</div>
            <div className="button-group-modal">
                <Button
                    variant="secondary"
                    onClick={() => handleClickDelete(holidayDetailId)}
                    disabled={isLoading}
                >
                    Delete
                </Button>
                <Button
                    customClass="button-separation"
                    variant="info"
                    onClick={handeClose}
                >
                    Cancel
                </Button>
            </div>
        </>
    );
};
